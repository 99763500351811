import React from "react"

import Seo from "../components/seo"
import {products} from "../properties/products"
import OpenSection from "../components/layouts/open-section"
import costanti from "../properties/costanti"
import ProductsGrid from "../components/ui/grid/grid"
import Svg from "../components/ui/svg"

export default function Products(){

    return(
        <>
            <Seo title="Catalogo Prodotti" description="Scropri tutti nostri prodotti, per cosa sono stati creati e i benefici che puoi trarne." />

            <div className={"w-full h-auto flex flex-col justify-center items-center text-justify text-xl"}>
                <div className={`group w-full flex flex-col items-center justify-center 
                text-secondary-600 dark:text-secondary-100 `+ costanti.theme.color.img.inverted}>
                    <OpenSection
                    leftText="I nostri prodotti"
                    leftSubChild=
                    {
                        <p className="text-white font-bold pt-5 portrait:pb-10">
                            Scropri i nostri prodotti, per cosa sono stati creati e i benefici che puoi trarne
                        </p>
                    }
                    rightChild=
                    {
                        <Svg.ShowProd className="group-hover:scale-110 group-hover:-hue-rotate-30 duration-500"/>
                    }
                    darkbg />
                </div>
            </div>

            <div className="relative w-full overflow-x-hidden overflow-y-auto -mt-20 md:-mt-40">
                <ProductsGrid list={products().list} />
            </div>

            <div data-sal="zoom-in" data-sal-duration="700"
            className="group flex w-full items-center justify-center mt-10 md:mt-20">
                <Svg.Factory className="w-2/3 group-hover:-hue-rotate-30 duration-700"/>
            </div>

        </>
    )
}
import React from "react"
import {GridElement} from "./element"

/**
 * @title Grid Component
 * @param {Array} list
 * @param {Boolean} transparentBg Card Background
 * @returns {React.Component} Div Html Element
 * @description Componente che ritorna un div con una griglia definita dalle classi Tailwind.
 * Viene utilizzata per visualizzare i prodotti al momento ma potrebbe essere generalizzata.
 */

// pt-8  mx-5 md:mx-4

const Grid = ({list, transparentBg}) => {
    return(
        <div className="overflow-hidden grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 py-10 md:py-20 md:gap-5" >
            {list.map((prodotto, index) => {
                return(
                    <GridElement key={index} title={prodotto.title} transparentBg={transparentBg}
                    description={prodotto.preview} icon={prodotto.icon}/>
                )
            })}
        </div>
    )
}

export default Grid
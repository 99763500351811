const { faBalanceScale, faClock, faTasks, faChartArea, faBox, faIdBadge, faStore, faTshirt } = require ('@fortawesome/free-solid-svg-icons')


/**
 * @title ProductsArray
 * @returns {Object} Object con un array di prodotti
 * @description Semplice componente che esporta i dati dei prodotti aziendali. In futuro si potrebbe sfruttare l'API di Gatsby
 * per l'export di questi dati. La sintassi è in es5 per poter essere importato anche nel file gatsby-node.js ed essere 
 * utilizzato con l'API di Gatsby createPage
 */

 module.exports = {

    products : function () {
        return(
        {
            list :
            [
                // {
                // title: "DigiLaw",
                // icon: faBalanceScale,
                // // img: <img src="/images/industry.jpg" alt="DigiLaw" className={img_class}/>,
                // imgUrl: "/images/industry.jpg",
                // preview: "Software in cloud dedicato al mondo giuridico/legale per la creazione e la condivisione di atti giudiziari",
                // request: "Creare un software che rappresenti un supporto alla compilazione degli atti giudiziari, una scrivania virtuale dove gli attori contribuisco ad alimentare la base di dati da cui attingere le informazioni in fase di compilazione dell'atto.",
                // solution: `Il prodotto si pone come obiettivo quello di supportare le diverse figure tramite l’ausilio di macroistruzioni e di maschere precompilate e personalizzabili. I diversi attori contribuiscono ad alimentare un set preconfigurato di informazioni minime su strutture dinamiche dove è possibile creare nuove alberature di dati complessi per permettere, poi, l'uso delle stesse anche agli altri attori. La condivisione continua dei dati e delle strutture permette un ampliamento consistente del sistema che di fatto viene autoalimentato dagli stessi attori.
                // La funzionalità di compilazione dell'atto, chiamata "Redattore", tutti i dati raccolti sono fruibili dall'utente in modo da supportarlo in fase di compilazione.`,
                // benefits:
                //     [
                //     "Struttura dinamica autoalimentata dagli stessi attori;",
                //     "Flessibilità sulla definizione di ogni singolo dato che andrà poi a comporre l'informazione nel suo complesso all'interno del dominio dell'applicazione;",
                //     "Fruibilità continua di tutti i dati legati alle varie entità del sistema;",  
                //     ],
                // tecnologies:
                //     [
                //         "JAVA J2EE",
                //         "VAADIN FLOW",
                //         "SPRING Boot",
                //         "SPRING Security"
                //     ]
                // },
                {
                title: "TiClock",
                icon: faClock,
                // img: <img src="/images/contact-3.jpg" alt="TiClock" className={img_class}/>,
                imgUrl: "/images/contact-3.jpg",
                preview: "Gestionale aziendale completo e flessibile per monitorare e coordinare i processi aziendali tramite un sistema di ticket e interventi",
                request: "Creare un software che permetta di monitorare le attività aziendali, di facile utilizzo, per il supporto direzionale e operativo.",
                solution: `Il prodotto si presenta come un'applicazione web installata in Cloud che offre diverse funzionalità in base al ruolo dell'utente che vi accede.
                Gli operatori possono inserire diverse tipologie di attività a partire dalle commesse inserite. Gli amministratori, oltre ad avere una visione completa su tutte le attività svolte e da pianificare,
                possono generare report del lavoro svolto, visualizzare le statistiche e il rendimento di un singolo utente e gestire le varie anagrafiche.`,
                benefits:
                    [
                        "Grazie ad un sistema di timer permette di tener traccia con precisione di ogni attività;",
                        "Notifiche configurabili per monitorare e coordinare facilmente i processi aziendali;",
                        "Estremamente flessibile e scalabile sull'esigenze dell'impresa;",
                        "La struttura modulare lo rende facilmente integrabile anche sistemi già esistenti;",
                    ],
                tecnologies:
                    [
                        "React Framework",
                        "TailwindCSS",
                        "REST API in PHP",
                        "Database PostgreSQL",
                    ]
                },
                {
                title: "TickRep",
                icon: faTasks,
                // img: <img src="/images/industry.jpg" alt="TickRep" className={img_class}/>,
                imgUrl: "/images/industry.jpg",
                preview: "Sistema di ticketing integrabile al proprio gestionale da offrire ai propri clienti",
                request: "Permettere ai clienti di accedere al proprio gestionale, inserire segnalazioni e visulizzarne lo stato di avanzamento, venendo avvisati qualora ci siano novità.",
                solution: `Portale web che si pone l'obiettivo di integrarsi a gestionali già esistenti, appoggiandosi alla stessa base dati e alle API già presenti. Tramite email precompilate e personalizzabili, notifica il cliente sullo stato di avanzamento delle segnalazioni aperte.`,
                benefits:
                    [
                        "Accellera i tempi per la gestione delle segnalazioni;",
                        "La struttura modulare permette di integrarsi facilmente al proprio gestionale;",
                        "Estremamente flessibile e scalabile sull'esigenze dell'impresa;",
                    ],
                tecnologies:
                    [
                        "React Framework",
                        "TailwindCSS",
                    ]
                },
                {
                title: "Prodocs",
                icon: faBox,
                // img: <img src="/images/industry.jpg" alt="Prodocs" className={img_class}/>,
                imgUrl: "/images/industry.jpg",
                preview:"Software sviluppato per il settore dei tendaggi per la gestione degli ordini e la stampa di modelli da condividere con il reparto di produzione",
                request: "Realizzare un software che permetta di tenere traccia e di gestire di tutti gli ordini effettuati e di fornire al reparto di produzione stampe con misure e modelli.",
                solution: `Il prodotto si presenta come una Web App molto intuitiva che, tramite maschere personalizzabili, permette di inserire ordini nel proprio archivio digitale. Oltre a ciò è in grado di prepare il documento da stampare per il reparto di produzione con i dettagli inseriti dall'operatore che vengono poi utilizzati per ricavarne ulteriori necessari per la creazione del modello ordinato.`,
                benefits:
                    [
                        "Accelera i tempi e ottimizza la comunicazione con il reparto di produzione;",
                        "Crea un archivio digitale degli ordini effettuati, consultabile ovunque;",
                        "Grazie ai layout pronti egli algoritmi presenti velocizza l'inserimento dei dati;",
                        "La struttura modulare lo rende molto flessibile e aperto all'aggiunta di nuove funzionalità;",
                    ],
                tecnologies:
                    [
                        "React Framework",
                        "TailwindCSS",
                        "REST API in PHP",
                        "PHP Symfony",
                        "Database PostgreSQL",
                    ]
                },
                {
                title: "Dig&Data",
                icon: faChartArea,
                // img: <img src="/images/industry.jpg" alt="Dig&Data" className={img_class}/>,
                imgUrl: "/images/industry.jpg",
                preview: "Soluzione per l'archiviazione centralizzata di informazioni e documenti in un unico repository in modo efficiente e sicuro",
                request: "Realizzare un software che permetta di sincronizzare i dati azeindali provenienti da uno o più Database e che ne permetta la riorganizzazione per analisi mirate.",
                solution: `La soluzione permette una gestione autonoma e libera dei dati aziendali tramite l’inserimento e/o l’esportazione personalizzata dai database dei vari gestionali,
                al fine di essere riorganizzati secondo le esigenze ed utilizzati per analisi mirate.
                Il prodotto punta a un’archiviazione centralizzata di tutte le informazioni e i documenti in un unico repository in modo semplice, efficiente e sicuro.
                `,
                benefits:
                    [
                        "Permette di creare un archivio digitale consultabile e gestibile ovunque;",
                        "Si adatta perfettamente a qualsiasi contesto grazie alla sua flessibilità;",
                        "La sua modularità lo rende integrabile in sistemi già esistenti;"
                    ],
                tecnologies:
                    [
                        "React Framework",
                        "TailwindCSS",
                        "REST API in PHP",
                        "PHP Symfony",
                        "Database PostgreSQL",
                    ]
                },
                {
                title: "TrackRP",
                icon: faIdBadge,
                // img: <img src="/images/industry.jpg" alt="TrackRP" className={img_class}/>,
                imgUrl: "/images/industry.jpg",
                preview: "Soluzione personalizzabile ideata e progettata per la rilevazione presenze, comprensiva di software e hardware",
                request: "Creare una soluzione comprensiva di software e hardware che permetta di monitorare i movimenti in entrata e in uscita del personale dipendente al fine di elaborare gli stipendi.",
                solution: `TrakRP permette la rilevazione e la gestione delle presenze del personale attraverso l’impiego della tecnologia RFID abbinata ai tradizionali sistemi di timbratura con badge. 
                In via opzionale TrakRP funziona anche con dispositivo di lettura biometrico con sensore di impronte digitali capacitivo.
                Il badge RFID, caricato con i dati identificativi del dipendente, passando in prossimità del timbratore ne rende possibile la rilevazione dei movimenti in ingresso e in uscita dal luogo di lavoro. 
                La funzione di BackOffice all’interno della gestione dei terminali permette lo scarico delle timbrature dei badge registrati a sistema e la rielaborazione dei dati.
                `,
                benefits:
                    [
                        "Rilevazione presenze dei dipendenti immediate e precise;",
                        "Download dei dati dal timbratore configurabile;",
                        "Possibilità di intervenire manualmente sui dati rilevati per correzioni o integrarne di nuovi;",
                        "Storicizzazione di tutte le operazioni;",
                        "Compatibile con diverse tipologie di timbratori;",
                        "La soluzione è multisede, con dati gestiti in server Cloud con l'ulteriore vantaggio di sollevare l'utente dall'onere di gesitre e conservare i dati a norma di legge;",
                        "Modulo aggiuntivo per la gestione da parte del dipendente dei propri dati di timbratura, quali l'immissione di giustificativi per permessi, ferie, trasferte, etc.;",
                        "Modulo aggiuntivo che consente l’export dei dati relativi alle timbrature su file utilizzabile per il caricamento dati sul sistema di elaborazione stipendi Omnia Zucchetti;",
                    ],
                tecnologies:
                    [
                        "Hardware basato su tecnologia RFID",
                        "Java",
                        "Magnolia CMS",
                    ]
                },
                {
                title: "TrackW",
                icon: faStore,
                // img: <img src="/images/industry.jpg" alt="TrackW" className={img_class}/>,
                imgUrl: "/images/industry.jpg",
                preview: "Soluzione per il tracciamento dei movimenti e la produzione reportistica relativa ai flussi degli articoli in entrata e in uscita in magazzino",
                request: "Realizzare un prodotto che permetta di monitorare i movimenti in entrata e in uscita degli articoli in magazzino e che permetta di creare report a partire dai dati registrati.",
                solution: `La soluzione permette la rilevazione e la tracciatura, attraverso l’impiego della tecnologia RFID, degli articoli catalogati e consente di ottenere la reportistica relativa ai flussi in entrata e uscita da un magazzino.
                Ogni articolo viene “etichettato” con l’applicazione di un trasmettitore RFID contenente le informazioni per la sua identificazione. 
                La funzione di BackOffice consente la visualizzazione e la rielaborazione di tutti i movimenti in entrata e in uscita degli articoli registrati a sistema rilevati ad ogni passaggio attraverso il varco costituito dai dispositivi di rilevazione RFID posizionati all’ingresso del magazzino.
                `,
                benefits:
                    [
                        "L'identificazione dell’articolo è immediata e precisa e la tracciatura della sua posizione un’operazione sicura e veloce;",
                        "All’amministrazione viene fornito a video un resoconto dei movimenti tracciati;",
                        "È possibile creare report in formato csv e pdf relativi ai dati sugli articoli presenti in magazzino;",
                        "È possibile censire velocemente gli articoli e sapere, soprattutto ai fini dell’organizzazione dei futuri carichi, quanti e quali mancano;",
                        "È possibile riassegnare il trasmettitore RFID ad esempio di un articolo danneggiato non più utilizzabile;",
                        "Possibilità di intervento in modo facile e veloce sui dati aquisiti automaticamente in fase di registrazione, di ingtegrare i dati con informazioni aggiuntive e di mantenere lo storico delle informazioni;",
                        "Sistema multisede con dati gestibili in server cloud ad alta protezione;",
                        "Modulo aggiuntivo per creare report personalizzati ed esportabili su altri sistemi operativi;",
                        "Modulo aggiuntivo per visualizzare, tramite la tecnologia di geolocalizzazione, i magazzini e la posizione degli articoli collegandosi con mappe online;",
                        "Modulo aggiuntivo che permette di determinare la posizione degli articoli all’interno del magazzino;"
                    ],
                tecnologies:
                    [
                        "Hardware basato su tecnologia RFID",
                        "Java",
                        "Magnolia CMS",
                    ]
                },
                {
                title: "TrackD",
                icon: faTshirt,
                // img: <img src="/images/industry.jpg" alt="TrackD" className={img_class}/>,
                imgUrl: "/images/industry.jpg",  
                preview: "Soluzione ideata per lo snellimento delle operazioni di lavanderia in strutture di accoglienza, comprensiva di hardware e software",
                request: "Progettare e sviluppare una soluzione completa (Hardware e Software) per tutte le strutture che offrono ai propri ospiti un servizio di lavanderia, stireria e pulitura che permetta di snellire le operazioni di smistamento e riconsegna degli indumenti lavorati.",
                solution: `La soluzione permette, attraverso l’impiego della tecnologia RFID, una gestione semplice, veloce e precisa delle operazioni di smistamento dei capi di abbigliamento in dotazione agli ospiti da parte degli operatori.
                In una gestione non automatizzata della fase di smistamento e riconsegna all’ospite degli indumenti lavorati l’operatore deve ricercare a mano sul singolo capo un'etichetta con il nome dell’ospite.
                TrakD permette di evitare questa operazione mediante l’applicazione iniziale su ogni capo di un apposito trasmettitore RFID, “intelligente” e resistente al lavaggio in lavatrice e alla stiratura, con le informazioni per l'identificazione del proprietario del capo. 
                Dopo ogni lavaggio /pulitura /stiratura, passando il capo in prossimità di un lettore RFID opportunamente posizionato, verranno visualizzate su un monitor le informazioni utili alla riconsegna del capo (nome ospite, reparto, stanza, etc.).
                `,
                benefits:
                    [
                        "Identificazione dei capi immediata e precisa;",
                        "Le indicazioni sul proprietario del capo e la sua posizione all’interno della struttura vengono fornite in tempo reale, limitando possibili errori di smistamento;",
                        "È facile gestire il processo di acquisizione e riconsegna dei capi anche se non si conoscono gli ospiti;",
                        "Vengono eliminate le difficoltà per la definizione della proprietà dei capi consegnati, garantendo ad ogni ospite di avere il proprio corredo sempre in ordine;",
                        "È possibile censire velocemente i capi di vestiario di ogni ospite e identificare, in relazione ad un corredo minimo, quali mancano;",
                        "L’interfaccia TrakD molto intuitiva e la rilevazione automatica dei dati permettono un risparmio dei costi in rapporto a tempo dedicato allo svolgimento delle operazioni e istruzione del personale per l'identificazione dei capi",
                        "Possibilità di riassegnare, in relazione alle esigenze della struttura, capi non più in uso ad uno specifico ospite;",
                        "Grazie ad un modulo aggiuntivo permette di valutare la produttività del reparto nel complesso e del singolo operatore (nel rispetto della normativa sulla privacy);",
                        "Grazie ad un modulo aggiuntivo permette, una volta catalogati gli indumenti, di trasferire il corredo da una sede ad un’altra con facilità;",
                        `Grazie ad un modulo aggiuntivo permette, associato all’acquisto di un ulteriore Lettore UHF, di avere una postazione aggiuntiva per la registrazione delle anagrafiche che operi in simultanea con quella della “lavanderia";`,
                    ],
                tecnologies:
                    [
                        "Hardware basato su tecnologia RFID",
                        "Java",
                        "Magnolia CMS",
                    ]
                }
                ],
            }
        )
    }
}
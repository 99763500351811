import React from "react"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import costanti from "../../../properties/costanti"
/**
 * @title GridElement Component
 * @param {Number} key
 * @param {HTMLElement} icon
 * @param {String} title
 * @param {String} description
 * @param {Boolean} transparentBg 
 * @returns {React.Component} Div Html Element
 * @description Componente che ritorna un div con una cella del Grid Component.
 */

export const GridElement = ({icon, title, description, transparentBg}) => {
    return(
        <AniLink
        paintDrip
        hex={costanti.theme.color.hex}
        // duration={1}
        className="w-full h-full"
        to={"/products/"+title?.toLowerCase()}>

            <div data-sal="slide-down" data-sal-duration="800"
            className={"h-full group mx-2 md:mx-5 px-4 md:px-7 py-5 lg:py-10 hover:scale-105 md:hover:scale-110 "+
            "rounded-tr-3xl rounded-bl-3xl shadow-xl transition duration-700 " + 
            (transparentBg ? "hover:shadow-blue-400 " : "bg-white hover:shadow-gray-400") } >

                <div className={`text-4xl md:text-5xl lg:text-6xl group-hover:animate-rotate-center
                group-hover:hue-rotate-30 duration-500 float-left my-3 mr-5 ml-0 ` +
                (transparentBg ? "text-primary-400 group-hover:text-emerald-400" : "text-emerald-500")}>
                    <FontAwesomeIcon icon={icon} />
                </div>
                
                <h1 className={`text-4xl py-3 group-hover:scale-105 text-left transition-transform duration-500 ` + 
                (transparentBg ? "text-white group-hover:text-emerald-400" : "text-secondary-600 font-bold ")}>
                    {title}
                </h1>
                <p className={`text-lg ` + (transparentBg ?
                "text-primary-400 group-hover:text-white" : "text-secondary-800") }>
                    {description}
                </p>
                
            </div>

        </AniLink>
    )
}